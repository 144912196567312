import styles from '../../styles/policy.module.css';



const PolisyTerms = () => {
    return (
        <div>
            <h4 className={styles.sectionTitleOrange} id="policyTerms">Terms</h4>
            <ol className={styles.dottedList}>
                <li><span>Data Controller</span>: A Data Controller has the responsibility of deciding how personal data is processed, the purpose for the data processing, and how to securely protect the personal data.</li>
                <li><span>Data Protection Act (DPA 2018)</span>: The DPA 2018 sets out the legal data protection framework in the UK. It contains three separate data protection regimes: 
                <ol className={styles.subDottedList}>
                <li><span>Part 2</span>: sets out a general processing regime (the UK GDPR);</li>
                <li><span>Part 3</span>: sets out a separate regime for law enforcement authorities;</li>
                <li><span>Part 4</span>: sets out a separate regime for the three intelligence services.</li>
                </ol>
                </li>
                <li><span>Data Subject</span>: A Data Subject is a living person who can be identified from personal data.</li>
                <li><span>GDPR</span>: This stands for General Data Protection Regulation (GDPR), the UK’s agreed standards for data protection that are also written into UK law through the Data Protection Act 2018 (DPA 2018).</li>
                <li><span>Individual Rights</span>: In UK data protection law, individuals have rights over their personal data. These rights allow the individual to ask the Data Controller to do something, or stop doing something with their personal data. There are eight individual rights.</li>
                <li><span>Information Commissioner’s Office (ICO)</span>: The Information Commissioner’s Office (ICO) is the UK’s independent body set up to uphold information rights.</li>
                <li><span>Lawful Basis</span>: A lawful basis is the legal reason or legal grounds relied upon for the processing of an individual’s personal data. There are six lawful bases to choose from: consent, contract, legal obligation, legitimate interest, public task, and vital interests.</li>
                <li><span>Personal Data</span>: Personal data is information about who you are, where you live, what you do, and more. It is all information that identifies you as a Data Subject.</li>
                <li><span>Privacy and Electronic Communications Regulations 2003 (PECR)</span>: PECR sits alongside the DPA 2018 and the UK GDPR. This legislation gives people specific privacy rights in relation to electronic communications, and electronic processing of their personal data.</li>
            </ol>
        </div>
    )
}

export default PolisyTerms;