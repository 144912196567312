import React, { useState, lazy, Suspense } from 'react';
import heroAlertDesktop from '../../assets/images/heroAlert_desk.webp'
import heroAlertMobile from '../../assets/images/heroAlert_mobile.webp'
import desktopImage from '../../assets/images/mainBgSmall.webp';
import mobileImage from '../../assets/images/bgMobile.webp';
import style from '../../styles/track.module.css';
import Spinner from '../sharedComponents/Spinner';

const PhoneVerificationComponent = lazy(() => import('../sharedComponents/PhoneVerification'));
const HeroImageComponent = lazy(() => import('../sharedComponents/HeroImageComponent'));

const EmergencyAlertHero = () => {

    const [toggleSearchInput, setToggleSearchInput] = useState(true);

    const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

    const togglePhoneVerificationPopup = (e) => {
        e.preventDefault();
        setToggleSearchInput(!toggleSearchInput);
    }
    return (
        <div className="mainContainer mainBgc" style={{ backgroundImage: `url(${imageUrl})` }}>
            <section className='heroInnerContainer'>
                <div className="heroContainer">
                    <h1 className="heroHeading">Stay <span className="hightLightText">Safe Anywhere</span> with Our Emergency Alert App</h1>
                    <p className={style.trackSubtitle}>Designed for those moments when seconds count. Our emergency alert app offers a quick and reliable way to send an SOS alert to your closest contacts. You’re only one tap away from notifying your loved ones when you need help. </p>
                    <div className='inputContainer'>
                        {toggleSearchInput && <PhoneVerificationComponent showPopUp={togglePhoneVerificationPopup} />}
                    </div>
                </div>
                <div>
                    <Suspense fallback={<Spinner />}>
                        <HeroImageComponent desktopImage={heroAlertDesktop} mobileImage={heroAlertMobile} />
                    </Suspense>
                </div>
            </section>
        </div>

    );
}

export default EmergencyAlertHero;