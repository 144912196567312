export const whyDtpFeatures = [
    {
        id: '1',
        title: 'Works Anywhere',
        content: 'Wherever life takes you, Device Tracker Plus has you covered. Our tracking works seamlessly across the globe, with no regional restrictions or hidden limitations.',
        imgName: 'earth'
    },
    {
        id: '2',
        title: 'Seamless Tracking',
        content: 'No complicated setup. No extra gadgets. Just reliable tracking that works straight from the devices you already own. Device Tracker Plus installs quickly and easily, so you can start tracking in minutes.',
        imgName: 'checkCircleIcon'
    },
    {
        id: '3',
        title: 'Find Lost Phones',
        content: 'As a Device Tracker Plus user, if you lose your phone, you’ll be able to track it instantly. Just log in to your account on a different device and get Google Maps directions to your lost device’s coordinates.',
        imgName: 'mobilePhoneIcon'
    },
    {
        id: '4',
        title: 'Privacy First',
        content: 'At Device Tracker Plus, we believe in responsible location sharing. No hidden monitoring, no sneaky tracking, just a simple, secure way to stay connected with the people who matter.',
        imgName: 'eye'
    },
];