import React from 'react';
import styles from '../../styles/whyDtp.module.css';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';

const WhyDtp = ({ showCheckoutPopup, reasons }) => {
  return (
    <>
      <section className={styles.main}>
        <div className='maxScreen'>
          <h1 className={styles.heading}>Why Thousands Trust Device Tracker Plus</h1>
          <div className={styles.featureGrid}>
            {reasons.map((feature) => (
              <div key={feature.id} className={styles.featureContainer}>
                <div className={styles.imageContainer}>
                  <img src={require(`../../assets/images/${feature.imgName}.png`)} alt="" />
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.featureTitle}>{feature.title}</div>
                  <div className={styles.featureContent}>{feature.content}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </section>
      <div className={styles.buttonContainer}>
        <StartTrackingButton title="Start Tracking Now" style={styles.startTrackingButton} onButtonClick={showCheckoutPopup} />
      </div>
    </>
  )
}

export default WhyDtp;