const StartTrackingButton = ({ style, title, onButtonClick }) => {


  const butttonClickHandler = (e) => {
    e.preventDefault();
    if (onButtonClick) {
      console.log("PopupStart");
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      onButtonClick();
    } else {
      console.log('No Popup')
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }


  return (
    <>
      <button
        id="startTracking"
        className={style}
        onClick={butttonClickHandler}
      >
        {title}
      </button>
    </>
  );
}

export default StartTrackingButton;