export const homeFeature_new = [
    {
        id: '1',
        title: 'Real-Time Location Tracking',
        link: '',
        partContent: '',
        linkContent: '',
        content: 'Whether you’re checking in on the kids, meeting up with friends, or making sure a family member gets home safe, our accurate, live GPS device tracking keeps everyone connected.',
        imgName: 'locationSharingCarousell'
    },
    {
        id: '2',
        title: '30-Day Location History',
        link: '',
        linkContent: '',
        partContent: '',
        content: 'You can track the routes and locations your loved ones and their devices took for the past month. Perfect for you to be able to give them the independence they want while maintaining your peace of mind.',
        imgName: 'locationHistoryCarousel'
    },
    {
        id: '3',
        title: 'Emergency Alert',
        content: 'In an emergency, every second counts. Users can send an instant SOS notification with their live location to everyone in your group at the bush of a button.',
        imgName: 'newEmergencyAlertCaousell'
    },
    {
        id: '4',
        title: 'Instant Messaging',
        content: 'Stay connected without switching between apps. Families and friends can chat and share updates directly in the app. No need to juggle texts and maps, everything is in one place.',
        imgName: 'messegesCarousel'
    },
    {
        id: '5',
        title: 'Location Alerts',
        content: 'Know when your kids get to school safely or when your partner leaves work, without having to ask. Set up geofences around common locations and get instant notifications when someone arrives or leaves.',
        imgName: 'familyCarousel'
    },
];