import styles from '../../styles/policy.module.css';



const Cookie = () => {
    return (
        <div>
            <h4 className={styles.sectionTitleOrange} id="cookies">Cookies</h4>
            <p className={styles.sectionSubtitle}>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.</p>
            <p className={styles.sectionSubtitle}>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our websites and allows us to improve our sites. As stated in the banner shown when you first visit, by continuing to browse the site, you are agreeing to our use of cookies on that site.</p>
            <p className={styles.sectionSubtitle}>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer. By using the website you are agreeing to using cookies. Cookies contain information that is transferred to your computer’s hard drive. Our sites may use one or more of the following sorts of cookies:</p>
            <ol className={styles.dottedList}>
                <li><span className="text-orange-100">Strictly necessary cookies</span>. These are cookies that are required for the operation of our websites. They include, for example, cookies that enable you to log into secure areas.</li>
                <li><span className="text-orange-100">Analytical/performance cookies</span>. They allow us to recognise and count the number of visitors and to see how visitors move around a website when they are using it. This helps us to improve the way our websites work, for example, by ensuring that users are finding what they are looking for easily.</li>
                <li><span className="text-orange-100">Functionality cookies</span>. These are used to recognise you when you return to a website. This enables us to personalise our content for you and remember your preferences.</li>
                <li><span className="text-orange-100">Targeting cookies</span>. These cookies record your visit to our websites, the pages you have visited and the links you have followed. We will use this information to make our websites and the advertising displayed on it more relevant to your interests.</li>
            </ol>
            <p className={styles.sectionSubtitle}>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
            <p className={styles.sectionSubtitle}>Examples of third party cookies providing external services include:</p>
            <ol className={styles.dottedList}>
                <li>Google Analytics</li>
                <li>OneTrust Cookie Consent Policy</li>
                <li>X (Formerly known as Twitte)</li>
                <li>Facebook/Meta</li>
                <li>Pinterest</li>
                <li>LinkedIn</li>
                <li>Instagram</li>
            </ol>
            <p className={styles.sectionSubtitle}>Further information about them may be obtained from their respective websites.</p>
            <p className={styles.sectionSubtitle}>If you wish to find out more about cookies, you may like to visit <a href="https://www.aboutcookies.org/" className={styles.linkOrange}> www.aboutcookies.org </a>which is an independent third-party resource that explains what cookies and clear gifs are and how you may manage them. Please note that we are not responsible for and have no control over the content of this third-party website.</p>
            <p className={styles.sectionSubtitle}>You may block cookies by activating the setting on your browser that allows you to disable the use of them. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our websites.</p>
            <p className={styles.sectionSubtitle}>Except for strictly necessary and functional cookies, cookies will expire within two years.</p>
        </div>
    )
}

export default Cookie