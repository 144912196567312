export const bottomFamlilyTrackingFeatures = [
    {
        id: '1',
        title: 'Intuitive and Easy to Use ',
        content: 'Our app is designed to be user-friendly, even for those who aren’t tech-savvy.',
        imgName: 'earth'
    },
    {
        id: '2',
        title: 'Clear, Real-Time Updates',
        content: 'Accurate live location data lets you check on your family without constant calls or texts.',
        imgName: 'checkCircleIcon'
    },
    {
        id: '3',
        title: 'Mutual Phone Tracking ',
        content: "Parents tracking children also share their own location, and our privacy mode lets users temporarily hide their location from the group.",
        imgName: 'mobilePhoneIcon'
    },
    {
        id: '4',
        title: 'Cross Platform Tracking',
        content: 'It doesn’t matter if you need to track your child’s iPhone location with an Android phone, our device tracker app works on all major platforms.',
        imgName: 'eye'
    },
];