import styles from '../../styles/testimonials.module.css';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useMediaQuery from '../../hooks/useMediaQuery';
import VerticalCarousel from './VerticalCarousel';


const Testimonials = ({ testimonials, showCheckoutPopup }) => {

  const isAboveMediumScreens = useMediaQuery("(min-width: 768px)");

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 798, min: 0 },
      items: 1
    }
  };

  return (
    <section className={styles.main}>
      <div className='maxScreen'>
        <h2 className={styles.heading} >
          See what our amazing customers have to say <br />
          about Device Tracker Plus
        </h2>
        <br />
        <div >
          {isAboveMediumScreens ? <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            // removeArrowOnDeviceType={["mobile"]}
          >
            {testimonials.map((testimonial) => (
              <div className={styles.testimonialContainer} key={testimonial.id}>
                <p className="paragraph">{testimonial.title}</p>
                <br></br>
                <br></br>
                <div>
                  ⭐⭐⭐⭐⭐
                </div>
                <br></br>
                <p className="smallParagraph">&quot;{testimonial.content}&quot;</p>
                <br></br>
                <br></br>
                <p className="smallParagraph hightLightText">{testimonial.author}</p>
              </div>
            ))}
          </Carousel>
            :
            <VerticalCarousel testimonials={testimonials} />
          }
        </div>
        <div className={styles.buttonContainer}>
          <StartTrackingButton title="See what all the fuss is about" style={styles.button} onButtonClick={showCheckoutPopup} />
        </div>
      </div>
    </section>
  );
}

export default Testimonials;