import React from 'react';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';
import styles from '../../styles/newHome.module.css';
import useMediaQuery from '../../hooks/useMediaQuery';



const Hero = ({ showCheckoutPopup }) => {

    const isAboveMediumScreens = useMediaQuery("(min-width: 500px)");

    return (
        <section>
            <div className={styles.heroBgImage}>
                <div className={styles.homeHeroInnerContainer}>
                    <div className='maxScreen'>
                        {!isAboveMediumScreens && <div className={styles.emptyContainer}></div>}
                        <div className={styles.heroContainer}>
                            <h1 className={styles.heading}><span className="hightLightText">Stay connected</span>
                                <br></br>with the world’s #1<br></br> device tracking app.</h1>
                            <p className={styles.paragraph}>
                                Life moves fast, and keeping track of the people
                                who matter shouldn’t be a challenge. Device
                                Tracker Plus has reliable, real-time location
                                tracking to give you peace of mind.</p>
                            <br />
                            <br />
                            <StartTrackingButton title="Start Tracking Now" style={styles.startTrackingButton} onButtonClick={showCheckoutPopup} />

                        </div>
                        <div className={styles.emptyContainer}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;