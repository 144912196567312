import styles from '../../styles/policy.module.css';

const List = () => {

  const scrollToSection = (sectionId) => {
    if (sectionId) {
      document.getElementById(sectionId).scrollIntoView({
        behavior: 'smooth'
      });
    }

  }

  return (
    <div className={styles.listContainer}>
      <h4 className={styles.sectionTitleOrange}>​​Table of Contents</h4>
      <ol className={styles.list}>
        <li onClick={() => scrollToSection('intro')} style={{ cursor: 'pointer' }}>Introduction.</li>
        <li onClick={() => scrollToSection('policyTerms')} style={{ cursor: 'pointer' }}>Terms.</li>
        <li onClick={() => scrollToSection('dataCollect')} style={{ cursor: 'pointer' }}>What data do we collect about you.</li>
        <li onClick={() => scrollToSection('howCollectData')} style={{ cursor: 'pointer' }}>How we collect your personal data:
          <ol className={styles.subList}>
            <li onClick={() => scrollToSection('directCollect')} style={{ cursor: 'pointer' }}>Information You Give Us Directly.</li>
            <li onClick={() => scrollToSection('howCollectData')} style={{ cursor: 'pointer' }}>Information we automatically Collect.</li>
          </ol>
          <ol className={styles.subUnnumList}>
            <li onClick={() => scrollToSection('sensitiveData')} style={{ cursor: 'pointer' }}>Sensitive Data.</li>
          </ol>
        </li>
        <li onClick={() => scrollToSection('useData')} style={{ cursor: 'pointer' }}>How we use your personal data:
          <ol className={styles.subUnnumList}>
            <li onClick={() => scrollToSection('marketingCom')} style={{ cursor: 'pointer' }}>Marketing Communications.</li>
            <li onClick={() => scrollToSection('research')} style={{ cursor: 'pointer' }}>Research and Development.</li>
          </ol>
        </li>
        <li onClick={() => scrollToSection('purpose')} style={{ cursor: 'pointer' }}>Change of Purpose.</li>
        <li onClick={() => scrollToSection('disclosure')} style={{ cursor: 'pointer' }}>Disclosures of your personal data:
          <ol className={styles.subUnnumList}>
            <li onClick={() => scrollToSection('serviceProviders')} style={{ cursor: 'pointer' }}>Service Providers</li>
            <li onClick={() => scrollToSection('regulators')} style={{ cursor: 'pointer' }}>Regulators and Authorities</li>
          </ol>
        </li>
        <li onClick={() => scrollToSection('international')} style={{ cursor: 'pointer' }}>International transfers.</li>
        <li onClick={() => scrollToSection('dataSec')} style={{ cursor: 'pointer' }}>Data security.</li>
        <li onClick={() => scrollToSection('dataRet')} style={{ cursor: 'pointer' }}>Data retention.</li>
        <li onClick={() => scrollToSection('legalRights')} style={{ cursor: 'pointer' }}>Your legal rights.</li>
        <li onClick={() => scrollToSection('thirdPartyLinks')} style={{ cursor: 'pointer' }}>Third-party links.</li>
        <ol className={styles.subUnnumList}>
          <li onClick={() => scrollToSection('cookies')} style={{ cursor: 'pointer' }}>Cookies</li>
        </ol>
        <li onClick={() => scrollToSection('version')} style={{ cursor: 'pointer' }}>Version 2.</li>
      </ol>
    </div>
  )
}

export default List;