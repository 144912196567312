import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function SignIn() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { currentUser, sendMagicLink, isSignInWithEmailLink, confirmSignIn } = useAuth();
    const navigate = useNavigate();

    // Check if user is already signed in
    useEffect(() => {
        if (currentUser) {
            navigate('/dashboard');
        }

        // Check if this is a sign-in link
        if (isSignInWithEmailLink()) {
            setLoading(true);

            confirmSignIn()
                .then(() => {
                    navigate('/dashboard');
                })
                .catch(error => {
                    setError('Failed to sign in: ' + error.message);
                    setLoading(false);
                });
        }
    }, [currentUser, navigate, isSignInWithEmailLink, confirmSignIn]);

    // Handle form submission
    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setMessage('');
            setError('');
            setLoading(true);

            const response = await fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + "account/checkEmail?email=" + email);
            if (response.ok) {

                const userId = await response.text();
                console.log('userId', userId);
                localStorage.setItem('userId', userId);
                localStorage.setItem('email', email);

                await sendMagicLink(email);
                setMessage('Check your email! We sent you a magic link to sign in.');
            } else {
                setError('Failed to send magic link: Email address not found in our database');
            }

        } catch (error) {
            setError('Failed to send magic link: ' + error.message);
        }

        setLoading(false);
    }

    return (
        <div className="auth-container">
            <h2>Sign In to your account</h2>

            {message && <div style={{ color: 'green', fontWeight: 'bold' }}>{message}</div>}
            {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
            <p></p>
            <form onSubmit={handleSubmit}>
                <div className="form-group">

                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder='Email address'
                        style={{ borderRadius: 10, padding: 10, width: 300, outline: '#F15223' }}
                    />
                </div>

                <button disabled={loading} type="submit"  >
                    {loading ? 'Processing...' : 'Send Magic Link'}
                </button>
            </form>
        </div>
    );
}