import { Helmet } from 'react-helmet';
import EmergencyAlertHero from '../components/heroSections/EmergencyAlertHero';
import Features from '../components/sharedComponents/Features';
import { emergencyAlertFeatureList } from '../data/emergencyAlertFeatureList';
import HowItWorks from '../components/sharedComponents/HowItWorks';
import WhyDtp from '../components/sharedComponents/WhyDtp';
import { bottomEmergencyAlertFeatures } from '../data/bottomEmergencyAlertFeatures';
import Testimonials from '../components/sharedComponents/Testimonials';
import { emergencyAlertTestimonials } from '../data/emergencyAlertTestimonials';

const EmergencyAlertCampaign = () => {
    return (
        <>
            <Helmet>
                <title>Emergency Alert App with Panic Button & SOS Alert</title>
                <meta name="description" content="Our SOS emergency alert app alerts your emergency contacts when you need help at the push of a button. Get peace of mind with Device Tracker Plus." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/emergencyAlert" />
            </Helmet>
            <EmergencyAlertHero />
            <Features sectionTitle="Stay Safe With A One-Tap Panic Button App" features={emergencyAlertFeatureList} />
            <HowItWorks stepOne="Sign up online" stepTwo="Download the app" stepThree="Add other contacts using group code"/>
            <Testimonials testimonials={emergencyAlertTestimonials}/>
            <WhyDtp reasons={bottomEmergencyAlertFeatures} />

        </>
    );
}

export default EmergencyAlertCampaign;