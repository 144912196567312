"use client"
import React, { useEffect, useState, useRef } from 'react';
import style from '../../styles/track.module.css';
import intlTelInput from 'intl-tel-input';
import '../../../src/intlTelInput.css';
import CheckoutPopup from './CheckoutPopup';
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from '@heroicons/react/24/solid';

let iti;

export default function PhoneVerification() {

    const [errorMessage, setErrorMessage] = useState('');
    const [readyToSearch, setReadyToSearch] = useState(false);
    const phoneInput = useRef({});
    const [isSearching, setIsSearching] = useState(false);
    const [trackingPossible, setTrackingPossible] = useState(false);
    const [carrierDataCountry, setCarrierDataCountry] = useState('');
    const [carrierDataCarrier, setCarrierDataCarrier] = useState('');
    const [carrierDataConfirmed, setCarrierDataConfirmed] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [showUserData, setShowUserData] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        iti = intlTelInput(phoneInput.current, {
            initialCountry: "auto",
            geoIpLookup: getCountry,
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });
        console.log("iti: ", iti.geoIpLookup);
    }, []);

    function getCountry(callback) {
        fetch(process.env.REACT_APP_PUBLIC_DEVICE_URL + 'Phone/country')
            .then(r => r.text())
            .then(data => callback(data));
    }

    async function startTracking(phoneNumber, countryCode) {
        setErrorMessage('');
        let inputModel = {
            CountryCode: countryCode,
            PhoneNumber: phoneNumber
        };
        // console.log("Input Model: ", inputModel);

        let res = await fetch(process.env.REACT_APP_PUBLIC_DEVICE_URL + 'Phone/carrier',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(inputModel),
            });

        console.log("Start Tracking res status: ", res.status);
        let data = await res.json();
        console.log("data  start tracking: ", data);
        if (data.error) {
            setErrorMessage(data.error);
            return;
        }

        if (!res.ok) {
            setErrorMessage('Something went wrong. Please contact support.');
            return;
        }

        setCarrierDataCountry(data.country);
        setCarrierDataCarrier(data.carrier);

        setCarrierDataConfirmed("Yes");
        setTrackingPossible(true);

        setIsSearching(true);
        setReadyToSearch(true);
    }

    function onPhoneSubmit(e) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        e.preventDefault();
        if (phoneInput.current.lenght > 4) {
            setIsSearching(!isSearching);
        } else {
            setErrorMessage('Invalid phone number');
        }


        if (!iti.isValidNumber()) {
            setErrorMessage('Invalid phone number');
            return;
        }

        startTracking(iti.getNumber(), iti.getSelectedCountryData().iso2);
    }

    const closePopup = () => {
        setIsSearching(!isSearching);
        setReadyToSearch(!readyToSearch);
        navigate(0);
    }

    const skipVerification = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setTimeout(() => { setCarrierDataCountry('No data'); }, 500);
        setTimeout(() => { setCarrierDataCarrier('No data'); }, 500);
        setTimeout(() => {
            setCarrierDataConfirmed("No data");
            setTrackingPossible(true);
        }, 500);
        setIsSearching(true);
        setShowUserData(false);
        setReadyToSearch(true);
    }

    return (
        <>
            {!isSearching &&
                <div className={style.formContainer}>
                    <p className={style.formHeader}>Enter the number you want to track</p>
                    {errorMessage ?
                        <div style={{ marginTop: '20px', color: 'red' }}>{errorMessage}</div>
                        : null}
                    <div className={style.inputContainer}>
                        <form onSubmit={onPhoneSubmit} className={style.form}>
                            <input type="tel" name="phone" id="phone" className={style.inputField} ref={phoneInput} />
                            <button className={style.btn} id='letsgo' type="submit" disabled={isButtonDisabled}>Let&lsquo;s go</button>
                        </form>
                    </div>
                    <p className={style.skip} onClick={skipVerification}>Skip this bit<ArrowRightIcon className={style.arrow}></ArrowRightIcon> </p>
                </div>}
            {readyToSearch &&
                <CheckoutPopup closePopup={closePopup} country={carrierDataCountry} carrier={carrierDataCarrier} confrmed={carrierDataConfirmed} trial={0} userData={showUserData} />}
        </>
    )
}