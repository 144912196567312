import React, { useEffect, useState } from 'react';
import style from '../../styles/checkoutPopup.module.css';
import { CheckIcon } from '@heroicons/react/24/solid';
import { TagManagerUtils } from '../../utils/TagManagerUtils';
import { appInsights } from '../azureAppInsights/azureAppInsights';
import earthIcon from '../../assets/images/earthIcon.webp';
import networkIcon from '../../assets/images/networkIcon.webp';
import markIcon from '../../assets/images/markIconSmall.webp';
import trustLogos from '../../assets/images/newTrustMobile.webp';
import exitIcon from '../../assets/images/Group 17.webp'

const CheckoutPopup = ({ closePopup, country, carrier, trial, confrmed, userData }) => {


    const [userCountry, setUserCountry] = useState('');
    const [network, setNetwork] = useState('');
    const [trackingCompatible, setTrackingCompatible] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [price, setPrice] = useState('');
    const [oldPrice, setOldPrice] = useState('');
    const [priceId, setPriceId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [freeTrial, setFreetiral] = useState(false);


    useEffect(() => {
        setTimeout(() => { setUserCountry(country) }, 1000);
        setTimeout(() => { setNetwork(carrier) }, 2000);
        setTimeout(() => { setTrackingCompatible(confrmed) }, 3000)
        if (userData) {
            setTimeout(() => { setIsConfirmed(true) }, 4000);
        } else {
            setTimeout(() => { setIsConfirmed(true) }, 750);
        }
        // scrollToTop()
    }, [carrier, country, confrmed, userData]);

    useEffect(() => {
        function showPrices(label, currecny, price) {
            setPrice(label);
            setOldPrice(priceLabel(price * 2, currecny));
            if (trial > 0) {
                setFreetiral(true);
            }
        }

        async function getPackage() {
            let params = new URLSearchParams(document.location.search.substring(1));
            let forceCountryCode = params.get('forceCountryCode');
            let additionalParams = '';
            if (forceCountryCode) {
                additionalParams = "&forceCountryCode=" + forceCountryCode
            }
            appInsights.trackEvent({ name: 'getPackage', properties: { forceCountryCode: forceCountryCode, productId: process.env.REACT_APP_PUBLIC_STRIPE_PRODUCT_ID } });
            const res = await fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + 'checkout/package?productid=' + process.env.REACT_APP_PUBLIC_STRIPE_PRODUCT_ID + additionalParams)

            if (!res.ok) {
                // This will activate the closest `error.js` Error Boundary
                appInsights.trackException({ exception: new Error('Failed to fetch package data') }, { statusText: res.statusText, status: res.status });
                throw new Error('Failed to fetch package data')
            }

            let data = await res.json();
            let price = data.prices[0];
            setPriceId(price.id);
            showPrices(price.label, price.currency, price.amount);
            localStorage.setItem('currency', price.currency);
            localStorage.setItem('amountPaid', price.amount);
            localStorage.setItem('priceId', price.id);
            console.log('localStorage set', {
                'amountPaid': price.amount,
                'currency': price.currency,
                'priceId': price.id
            });
            appInsights.trackTrace({ message: 'Package data fetched', properties: data });
            TagManagerUtils.sendEventWithEcommerceData('onCheckoutView');
        }

        getPackage();
    }, [trial]);


    function initStripeSession(email) {

        setIsLoading(true);
        let params = new URLSearchParams(document.location.search.substring(1));
        let forceCountryCode = params.get('forceCountryCode');
        appInsights.trackEvent({ name: 'initStripeSession', properties: { email: email, forceCountryCode: forceCountryCode } });
        console.log("initStripe Session email: ", email);
        fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + 'checkout/session',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    priceId: priceId,
                    returnPage: "/",
                    trial: trial,
                    productId: process.env.REACT_APP_PUBLIC_STRIPE_PRODUCT_ID,
                    forceCountryCode: forceCountryCode
                }),
            }
        )
            .then(response => response.json()) // no content
            .then(data => {

                if (localStorage.getItem('priceId') === '' && data.price) {
                    let price = data.price;
                    setPriceId(price.id);
                    localStorage.setItem('currency', price.currency);
                    localStorage.setItem('amountPaid', price.amount);
                    localStorage.setItem('priceId', price.id);
                }

                localStorage.setItem('userId', data.userId);
                localStorage.setItem('email', email);

                console.log('localStorage set', {
                    'userId': data.userId,
                    'email': email
                });

                window.dataLayer = window.dataLayer || [];

                window.dataLayer.push({
                    'event': 'userData',
                    'user_id': data.userId
                });

                TagManagerUtils.sendEventWithEcommerceData('onEmailSubmit');

                if (data.isPaid && data.code) {
                    window.location.replace("/welcome");
                    return;
                }

                if (data.checkoutUrl) {
                    window.location = data.checkoutUrl;
                }
            })
            .catch((error) => {
                appInsights.trackException({ exception: new Error('Failed to fetch session data') }, { error: error });
                console.log("there was an error")
                console.error(error)
            });
    }


    function priceLabel(packagePrice, packageCurrency) {

        if (packageCurrency.toLocaleUpperCase() === "USD")
            return "$" + packagePrice.toFixed(2);
        if (packageCurrency.toLocaleUpperCase() === "GBP")
            return "£" + packagePrice.toFixed(2);
        if (packageCurrency.toLocaleUpperCase() === "EUR")
            return "€" + packagePrice.toFixed(2);
        return "" + packagePrice.toFixed(2) + " " + packageCurrency;
    }

    return (
        <div className={style.popup}>
            {userData ?
                <>
                    <div className={style.dismissContainer} id='topPopup'>
                        <div className={style.dismiss} onClick={closePopup}><img src={exitIcon} alt="exit_Icon" /></div>
                    </div>
                    <div className={style.headerContainer}>
                        <h1 className={style.header}>Moblie information:</h1>
                    </div>
                    <div className={style.verificationContainer}>
                        {userCountry && <div className={style.verificationResult}>
                            <div className={style.resultBlock}><img src={earthIcon} alt="earth_Icon" /><p >Location:</p></div><span className={style.result}>{userCountry}</span>
                        </div>}
                        {network && <div className={style.verificationResult}>
                            <div className={style.resultBlock}><img src={networkIcon} alt="Network_Icon" /><p >Network:</p></div><span className={style.result}>{network}</span>
                        </div>}
                        {trackingCompatible && <div className={style.verificationResult}>
                            <div className={style.resultBlock}><img src={markIcon} alt="mark_Icon" /><p style={{ color: '#F15223' }}>Tracking available:</p></div><span className={style.resultNetwork}>{trackingCompatible}</span>
                        </div>}
                    </div>
                    {isConfirmed &&
                        <div>
                            <div className={style.buttonContainer}>
                                <button className={style.startTrackingButton} onClick={() => initStripeSession()}>Start Tracking Now</button>
                            </div>
                            <div className={style.priceContainer}>
                                <div className={style.halfPriceBox}>
                                    <p className={style.halfPrice}>LIMITED TIME 50% OFF</p>
                                </div>
                                <div style={{ marginLeft: '0px' }}>
                                    <p style={{ marginBottom: '10px', marginTop: '10px' }}><span className={style.newPrice}>{price}</span> /month</p>
                                    <p className={style.oldPrice}>Normally {oldPrice} / month</p>
                                </div>
                            </div>
                            <div className={style.trustLogosContainer}>
                                <img src={trustLogos} alt="trust_payment_method_banner" className={style.trustLogo} />
                            </div>
                        </div>
                    }
                </> :
                <div className={style.dismissContainer} id='topPopup'>
                    <div className={style.dismiss} onClick={closePopup}><img src={exitIcon} alt="exit_Icon" />
                    </div>
                    <div>
                        <div className={style.priceContainer}>
                            <div className={style.halfPriceBox}>
                                <p className={style.halfPrice}>LIMITED TIME 50% OFF</p>
                            </div>
                            <div style={{ marginLeft: '0px' }}>
                                <p style={{ marginBottom: '10px', marginTop: '10px' }}><span className={style.newPriceOrange}>{price}</span> /month</p>
                                <p className={style.oldPrice}>Normally {oldPrice} / month</p>
                            </div>
                        </div>
                        <div className={style.buttonContainer}>
                            <button className={style.startTrackingButton} onClick={() => initStripeSession()}>Start Tracking Now</button>
                        </div>

                        <div className={style.featureContainer}>
                            <ul className={style.featureList}>
                                <li >
                                    <CheckIcon className={style.featureIcon} /><p className={style.feature}>Track <span className="hightLightText">5</span> devices at once</p>
                                </li>
                                <li>
                                    <CheckIcon className={style.featureIcon} /><p className={style.feature}><span className="hightLightText">Unlimited</span> tracking</p>
                                </li>
                                <li >
                                    <CheckIcon className={style.featureIcon} /><p className={style.feature}>Up to <span className="hightLightText">30 days</span> location history</p>
                                </li>
                                <li >
                                    <CheckIcon className={style.featureIcon} /><p className={style.feature}>Track <span className="hightLightText">Apple & Android</span></p>
                                </li>
                                <li >
                                    <CheckIcon className={style.featureIcon} /><p className={style.feature}>Works on <span className="hightLightText">all networks</span></p>
                                </li>
                            </ul>
                        </div>
                        <div className={style.trustLogosContainer}>
                            <img src={trustLogos} alt="trust_payment_method_banner" className={style.trustLogo} />
                        </div>
                    </div>
                </div>}
        </div>
    );
}
export default CheckoutPopup;