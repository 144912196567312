import styles from '../../styles/policy.module.css';

const Regulations = () => {
    return (
        <div>
            <h4 className={styles.sectionTitleOrange} id="marketingCom">Marketing Communications</h4>
            <p className={styles.sectionSubtitle}>You will receive marketing communications, including emails, newsletters, mailings, or SMS messages, from us if you have:</p>
            <ol className={styles.dottedList}>
                <li>requested information from us or purchased goods or services from us; or</li>
                <li>if you provided us with your details and ticked the box at the point of entry of your details for us to send you marketing communications; and</li>
                <li>in each case, you have not opted out of receiving that marketing.</li>
            </ol>
            <p className={styles.sectionSubtitle}>We are aware of our obligations to data privacy under UK GDPR, Privacy and Electronic Communications Act 2003 and the Data Protection Act 2018. We will always get your express consent to opt-in prior to sharing any personal data with third-parties for marketing or other purposes. We currently partner with the following organisations; Results Generation and GoGroupie.</p>
            <p className={styles.sectionSubtitle}>You can ask us or third-parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or or by emailing us at <a href="mailto:support@devicetrackerplus.zendesk.com" className={styles.linkOrange}> support@devicetrackerplus.com </a> at any time.</p>
            <p className={styles.sectionSubtitle}>Where you opt out of receiving our marketing communications, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</p>

            <h4 className={styles.sectionTitleOrange} id="research">Research and Development</h4>
            <p className={styles.sectionSubtitle}>We may use the information we collect for the following purposes:</p>
            <ol className={styles.dottedList}>
                <li>To understand how users engage with our products and services, including monitoring the popularity of features, metrics, and trends.</li>
                <li>To conduct market research and analytics, both individually and collectively.</li>
                <li>To enhance the delivery of our existing products and services.</li>
                <li>To develop new products and services.</li>
                <li>To perform research and internal analysis.</li>
            </ol>

            <h4 className={styles.sectionTitleOrange} id="purpose">Change of Purpose</h4>
            <p className={styles.sectionSubtitle}>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to find out more about how the processing for the new purpose is compatible with the original purpose, please email us at <a href="mailto:support@devicetrackerplus.zendesk.com" className={styles.linkOrange}> support@devicetrackerplus.com</a></p>
            <p className={styles.sectionSubtitle}>If we need to use your personal data for a purpose unrelated to the purpose for which we collected the data, we will notify you and we will explain the legal ground of processing.</p>
            <p className={styles.sectionSubtitle}>We may process your personal data without your knowledge or consent where this is required or permitted by law.</p>

            <h4 className={styles.sectionTitleOrange} id="disclosure">Disclosures of your personal data</h4>
            <ol className={styles.dottedList}>
                <li>We may have to share your personal data with the third parties set out below for our or their purposes::HM Revenue & Customs, regulators and other authorities based in the United Kingdom and other relevant jurisdictions who require reporting of processing activities in certain circumstances.Third parties to whom we sell, transfer, or merge parts of our business or our assets. </li>
            </ol>

            <h4 className={styles.sectionTitleOrange} id="serviceProviders">Service Providers</h4>
            <p className={styles.sectionSubtitle}>We may share your personal information with our vendors and consultants who assist us in delivering our services or perform tasks on our behalf. This includes services related to accounting, management, insurance, legal or technical support, email or chat services, marketing, analytics, fraud prevention, bot detection, web hosting, and other services or features provided to our members.</p>

            <h4 className={styles.sectionTitleOrange} id="regulators">Regulators and Authorities</h4>
            <p className={styles.sectionSubtitle}>We may disclose personal information to HM Revenue & Customs, regulators and other authorities based in the United Kingdom and other relevant jurisdictions who require reporting of processing activities in certain circumstances.</p>

            <h4 className={styles.sectionTitleOrange}>Third Party Purchases</h4>
            <p className={styles.sectionSubtitle}>We may at any time sell, transfer, or merge parts of our business or our assets. Personal data such as member names and email addresses, and other personal data related to our services will likely be one of the items transferred in these types of transactions.</p>
            <p className={styles.sectionSubtitle}>We require all third parties to whom we transfer your data to respect the security of your personal data and to treat it in accordance with the law. We only allow such third parties to process your personal data for specified purposes and in accordance with our instructions. </p>

            <h4 className={styles.sectionTitleOrange}>Protecting Children</h4>
            <p className={styles.sectionSubtitle}>We are committed to protecting the privacy of children that use Device Tracker Plus. Our service is only intended to be used by children with a parent or guardian’s permission and involvement.</p>
            <p className={styles.sectionSubtitle}>If we learn that we have unknowingly collected personal data from a child without the consent of a parent or guardian, we will take all steps required by law to delete this information. As a parent or guardian, allowing your child to create a member account and/or permitting your child to use our services, you expressly agree to the practices described in this Privacy Policy and you consent to the collection, use, and disclosure of your child’s personal data as described.</p>
            <p className={styles.sectionSubtitle}>A parent or legal guardian has a right to request to review the child’s personal data, or have it deleted, and may withdraw consent for us to collect any further personal data from the child. Please contact us at support@devicetrackerplus.com to arrange this. We will require the parent or guardian to provide us with sufficient identification before acting upon such a request.</p>
            <p className={styles.sectionSubtitle}>If you discover that a child in your care has created an account through our services without your consent, please contact us as soon as possible at <a href="mailto:support@devicetrackerplus.zendesk.com" className={styles.linkOrange}> support@devicetrackerplus.com</a></p>

            <h4 className={styles.sectionTitleOrange} id="international">International transfers</h4>
            <p className={styles.sectionSubtitle}>We are a global business. Personal Data may be stored and processed in any country where we have operations or where we engage third party service providers. We may transfer Personal Data that we maintain about you to recipients in countries other than the country in which the Personal Data was originally collected, including to the United States. Those countries may have data protection rules that are different from those of your country. However, we will take measures to ensure that any such transfers comply with applicable data protection laws and that your Personal Data remains protected to the standards described in this Privacy Policy. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled to access your Personal Data.</p>
            <p className={styles.sectionSubtitle}>If you are located in the European Economic Area (“EEA”) or Switzerland, we comply with applicable laws to provide an adequate level of data protection for the transfer of your Personal Data to the US.</p>
            <p className={styles.sectionSubtitle}>Where applicable law requires us to ensure that an international data transfer is governed by a data transfer mechanism, we use one or more of the following mechanisms: EU Standard Contractual Clauses with a data recipient outside the EEA, verification that the recipient has implemented Binding Corporate Rules, or verification that the recipient adheres to the EU-US and Swiss-US Privacy Shield Framework.</p>

            <h4 className={styles.sectionTitleOrange} id="dataSec">Data security</h4>
            <p className={styles.sectionSubtitle}>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know such data. They will only process your personal data on our instructions, and they are subject to a duty of confidentiality.</p>
            <p className={styles.sectionSubtitle}>We have put in place measure to reduce the risk of an unlawful data breach, and procedures to deal with any suspected personal data breach. In the unlikely event of a breach where data under our control is compromised, we will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

            <h4 className={styles.sectionTitleOrange} id="dataRet">Data retention</h4>
            <p className={styles.sectionSubtitle}>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
            <p className={styles.sectionSubtitle}>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
            <p className={styles.sectionSubtitle}>By law we must keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers for tax and accounting purposes.</p>
            <p className={styles.sectionSubtitle}>In some circumstances you can ask us to delete your data: see below for further information.</p>
            <p className={styles.sectionSubtitle}>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>

            <h4 className={styles.sectionTitleOrange} id="legalRights">Your legal rights</h4>
            <p className={styles.sectionSubtitle}>Under certain circumstances, you have rights under data protection laws in relation to your personal data. These include the right to:</p>
            <ol className={styles.dottedList}>
                <li>Request access to your personal data.</li>
                <li>Request correction of your personal data</li>
                <li>Request erasure of your personal data.</li>
                <li>Object to processing of your personal data.</li>
                <li>Request restriction of processing your personal data.</li>
                <li>Request transfer of your personal data.</li>
                <li>Right to withdraw consent.</li>
            </ol>
            <p className={styles.sectionSubtitle}>You can see more about these rights at: <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/individual-rights/individual-rights/" className={styles.linkOrange}>https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/individual-rights/individual-rights/</a>. If you wish to exercise any of the rights set out above, please email us at <a href="mailto:support@devicetrackerplus.zendesk.com" className={styles.linkOrange}> support@devicetrackerplus.com</a></p>
            <p className={styles.sectionSubtitle}>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
            <p className={styles.sectionSubtitle}>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
            <p className={styles.sectionSubtitle}>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made several requests. In this case, we will notify you and keep you updated.</p>

            <h4 className={styles.sectionTitleOrange} id="thirdPartyLinks">Third-party links</h4>
            <p className={styles.sectionSubtitle}>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.</p>
        </div>
    )
}

export default Regulations