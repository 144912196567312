import FamilyTrackingHero from "../components/heroSections/FamilyTrackingHero";
import Features from '../components/sharedComponents/Features';
import HowItWorks from "../components/sharedComponents/HowItWorks";
import WhyDtp from "../components/sharedComponents/WhyDtp";
import Testimonials from "../components/sharedComponents/Testimonials";
import { Helmet } from 'react-helmet';
import { bottomFamlilyTrackingFeatures } from "../data/bottomFamlilyTrackingFeatures";
import { familyTrackingTestimonials } from "../data/familyTrackingTestimonials";
import { familyTrackingFeaturesList } from "../data/familyTrackingFeaturesList";

const FamilyTracking = () => {
    return (
        <>
            <Helmet>
                <title>Family Location Tracking App for Parents - Device Tracker Plus</title>
                <meta name="description" content="Track your child or teenager with our accurate family location tracker app. Ensure your family is safe while allowing them independence. Download now." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/familyTracking" />
            </Helmet>
            <FamilyTrackingHero />
            <Features sectionTitle="Track Your Family’s Location Anytime, Anywhere" features={familyTrackingFeaturesList} />
            <HowItWorks stepOne="Sign up online" stepTwo="Register device" stepThree="Start Tracking" />
            <Testimonials testimonials={familyTrackingTestimonials} />
            <WhyDtp reasons={bottomFamlilyTrackingFeatures} />

        </>
    );
}

export default FamilyTracking;