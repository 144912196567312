import styles from '../../styles/policy.module.css';


const Introduction = () => {

    return (
        <div>
            <h4 className={styles.sectionTitleOrange} id='intro'>Introduction</h4>
            <p className={styles.sectionSubtitle}>
                Whilst every effort has been made to outline our responsibilities to you in as clear, concise, and easy to understand manner as possible, we do need to use certain terms throughout this Privacy Notice.
            </p>
        </div>
    )
}

export default Introduction