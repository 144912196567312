import Carousel from 'react-multi-carousel';
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from '../../styles/carousel.module.css';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';

const Features = ({ features, showCheckoutPopup }) => {

  const isAboveMediumScreens = useMediaQuery("(min-width: 1026px)");

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 749, min: 0 },
      items: 1
    }
  };

  return (
    <section className={styles.mainSection}>
      <div className={styles.maxWidth}>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          infinite={false}
          autoPlay={false}
          arrows={true}
          keyBoardControl={true}
          removeArrowOnDeviceType={["mobile"]}
        >
          {features.map(({ id, title, content, imgName, link, linkContent, partContent }) => (
            <div key={id}
              className={styles.featureCarouselContainer}
            >
              <div className={styles.featureImageContainer}>
                <img src={isAboveMediumScreens ? require(`../../assets/carouselImages/${imgName}_desk.webp`) : require(`../../assets/carouselImages/${imgName}_mobile.webp`)} alt="carousel_image" />
              </div>
              <div className={styles.featureTextContainer}>
                <p className={styles.featureTitle}>{title}</p>
                <p className={styles.featureContent}>{partContent}{link ? <a href={link}>{linkContent}</a> : null}{content}</p>

              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.buttonContainer}>
        <StartTrackingButton title="Start Tracking Now" style={styles.startTrackingButton} onButtonClick={showCheckoutPopup} />
      </div>
    </section>
  );
}

export default Features;