import styles from '../styles/terms.module.css';
import { Helmet } from 'react-helmet';

const Terms = () => {

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/terms" />
            </Helmet>

            <div className={styles.mainPageContainer}>
                <div className={styles.fullInnerCard}>
                    <h1 className={styles.pageTitle}>Terms and Conditions for Device Tracker Plus</h1>
                    <br />
                    <h4 className={styles.sectionSubtitle}><span style={{ textDecoration: 'underline' }}>Effective Date:</span> 1st February 2025</h4>
                    <br />
                    <h4 className={styles.sectionSubtitle}><span style={{ textDecoration: 'underline' }}>Last Updated:</span> 1st February 2025</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>Welcome to Device Tracker Plus. These Terms and Conditions outline the rules and regulations for using our digital subscription service. By subscribing, you demonstrate that you have read, understood, and agree to comply with these terms (the “Agreement”). This agreement applies to all visitors and subscribers who use or access our services. Before accessing or using our services, please read the Privacy Policy and understand how we collect, store, use and disclose your personal data.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>1. About Us</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>Device Tracker Plus is provided by Boston Digital Limited, a company registered in England & Wales under company registration number 10226469. Our registered office is located at Office 6, 25 St. Andrews Street, Hertford, Herts, SG14 1HZ. You can contact us at <a href="mailto:support@devicetrackerplus.com" className={`${styles.linkOrange}`}>support@devicetrackerplus.com </a></p>
                    <br />

                    <h4 className={styles.sectionTitleOrange}>2. Eligibility</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>To use our services, you must be at least eighteen (18) years old, and provide accurate and complete information during registration to firm a binding contract with us. Anyone under eighteen (18) years of age are prohibited from creating an account to subscribe to the service without consent from a parent or guardian.</p>
                    <p className={styles.sectionSubtitle}>By consenting to their child's use of the service, parents and guardians agree to the following:</p>
                    <ol className={styles.list}>
                        <li>(i) They will be fully responsible for and oversee the child's use of the Services, ensuring that this use always complies with this agreement, all referenced documents, and all applicable laws.</li>
                        <li>(ii) They will be completely accountable for the child's adherence to this agreement and their use of the services.</li>
                        <li>(iii) They will assume full responsibility for the disclaimers, waivers, and limitations of liability outlined in this agreement, both on their own behalf and on behalf of the child.</li>
                    </ol>
                    <p className={styles.sectionSubtitle}>Anyone who has been previously removed from the service by us is no longer eligible to use this service.</p>
                    <br />

                    <h4 className={styles.sectionTitleOrange}>3. Service Description</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>Device Tracker Plus is a mobile tracking app. The app is designed to help people locate lost or stolen devices, including smartphones, tablets, and laptops, using GPS technology to provide accurate location information. Legitimate examples of service use include:</p>
                    <ol className={styles.dottedList}>
                        <li>To track your lost stolen mobile – please note you must have the app installed on the device for this to be possible.</li>
                        <li>To track your family member or any person under your guardianship.</li>
                        <li>To track devices you own which are distributed to your employees.</li>
                        <li>To test our Service by tracking your smart phone or tablet.</li>
                    </ol>
                    <h4 className={styles.sectionSubtitleOrange}>Specific Conditions</h4>
                    <ol className={styles.dottedList}>
                        <li>You are solely responsible to have obtained the permission of the person whose smart phone or tablet is being tracked prior to use of Device Tracker Plus if you are required to do so under any applicable laws of your local jurisdiction.</li>
                        <li>You acknowledge and represent that you shall not use Our Services with the intentions to infringe the privacy of any person. If you believe that your privacy rights are infringed without your consent and authorization, you should immediately notify us with the proof of such infringement.</li>
                        <li>You are responsible to ensure that you are in strict compliance with the Terms and Conditions and the applicable laws of your local jurisdiction.</li>
                        <li>You must not use the Device Tracker Plus in any way which is inappropriate, illegal, or contrary to the Terms and Conditions, or which amounts to the infringement of the privacy of any person.</li>
                        <li>Before using Device Tracker Plus you must pay the Subscription Fees for the package you selected.</li>
                        <li>We will provide you with all applicable features of Device Tracker Plus once the Subscription Fee has been paid, however we reserve the right to modify such features at any time at our sole discretion. Applicable features will be dependent on the package selected at the time of subscription.</li>
                        <li>You acknowledge that positional accuracy may be affected by several technical factors that are not within our control and that we cannot guarantee to that effect.</li>
                        <li>We own no liability for losses, costs, expense or your efforts caused, as a consequence of your reliance upon the report of your mobile position tracked by us.</li>
                    </ol>
                    <p className={styles.sectionSubtitle}>The Service is available on a subscription basis only, with the details outlined below.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>4. Communications</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>By providing Device Tracker Plus with your email address, you consent to use sending you service-related information, including any information required by law, by email instead of by post. We may also use you email address to send you other messages, such as promotional information, marketing communications and changes to features of our services. If you do not want to receive these messages, you can unsubscribe at any time from such communications from Device Tracker Plus. Users that require opt-in consent by law for these communications will be given the opportunity to opt in before receiving any promotional messaging.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>5. Subscription Plans and Fees</h4>
                    <br />
                    <ol className={styles.dottedList}>
                        <li>By subscribing to our services, you agree to the pricing and payment terms shown at the time of your subscription. We reserve the right to amend fees and charges at any time. You will be notified of any changes to fees at least 30 days in advance.</li>
                        <li>All Device Tracker Plus subscriptions are paid on a monthly basis. All fees are payable in advance at the beginning of your subscription term, and at the start of each billing period thereafter. Your subscription will automatically renew until you cancel it. We (or a third-party payment processor) will charge you each month on the anniversary of the start of your monthly subscription.</li>
                        <li>Payments are processed securely via Stripe Payment Gateway. You also have the option to pay with PayPal, GPay or Link.</li>
                        <li>A receipt for your initial payment will be sent to the email address used to create your account, if you require a monthly receipt for your payment you will need to request this each month via the contact page.</li>
                        <li>Device Tracker Plus is not responsible for additional costs incurred as a result of using our services, such as fees for mobile data usage. Consult your provider with questions related to billing, as data rate charges and limits may apply.</li>
                    </ol>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>6. Free Trials</h4>
                    <p className={styles.sectionSubtitle}>Free trials are not available for this service.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>7. 14-Day Cancellation Waiver for Digital Services</h4>
                    <p className={styles.sectionSubtitle}>By purchasing this digital service, you acknowledge and agree to the following terms regarding your right to cancel:</p>
                    <ol className={styles.list}>
                        <li>1. Immediate Access: You request and consent to the immediate provision of the digital service, which will be made available to you immediately after purchase.</li>
                        <li>2. Waiver of Cancellation Rights: Under applicable consumer protection laws, you have the right to cancel your purchase within 14 days without providing a reason. However, by agreeing to the immediate commencement of the service, you waive your right to cancel once the service has been fully delivered or accessed.</li>
                        <li>3. Confirmation: By ticking the relevant box or otherwise agreeing during checkout, you confirm your understanding of and consent to this waiver.</li>
                        <li>4. Exceptions: If you have not accessed or used the digital service, your statutory cancellation rights may still apply during the 14-day period. Please contact us if you wish to exercise these rights by emailing us here: <a href="mailto:support@devicetrackerplus.com" className={`${styles.linkOrange}`}>support@devicetrackerplus.com</a></li>
                    </ol>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>8. Account Usage</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>We grant you a non-exclusive, non-transferable, and freely revocable right to access and use our services on devices that you own or control. This access is for your personal use only and is limited to the features of the service. We may terminate this right at any time if you materially or repeatedly fail to comply with any provisions of this agreement, if you do not follow instructions from our customer support representatives, if there are reasons beyond our control, or if we stop providing the service.</p>
                    <p className={styles.sectionSubtitle}>You are solely responsible for all activities that occur on your account, and you must keep your account password secure. Please notify us immediately if you become aware of any security breaches or unauthorised use of your account.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>9. Content Access and Restrictions</h4>
                    <p className={styles.sectionSubtitle}>Content is licensed for personal use only. You must not reproduce, share, or distribute the content without permission.</p>
                    <p className={styles.sectionSubtitle}>We strive to ensure uninterrupted access but cannot guarantee availability due to technical issues or maintenance.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>10. Refunds</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>Refunds may be provided at our discretion where required under UK consumer law. Contact <a href="mailto:support@devicetrackerplus.com" className={`${styles.linkOrange}`}>support@devicetrackerplus.com</a> for refund requests.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>11. Privacy and Data Protection</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>We handle your data in accordance with our Privacy Policy (<a href="/policy" className={`${styles.linkOrange}`}>https://www.devicetrackerplus.com/policy</a>). Your personal data is processed lawfully and securely under GDPR.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>12. Liability</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>Our liability:</p>
                    <ol className={styles.dottedList}>
                        <li>If we fail to comply with these terms and conditions, our maximum aggregate liability shall not, in any circumstances, be in excess of the Subscription Charges.</li>
                        <li>We will not be liable for losses that result from our failure to comply with these terms and conditions that fall into the following categories:
                            <ol className={styles.subDottedList}>
                                <li>loss of income or revenue.</li>
                                <li>loss of business.</li>
                                <li>loss of profits; or</li>
                                <li>loss of anticipated savings.</li>
                                <li>Nothing in this agreement excludes or limits our liability for:</li>
                                <li>death or personal injury caused by our negligence.</li>
                                <li>fraud or fraudulent misrepresentation.</li>
                                <li>any other matter for which it would be illegal for us to exclude or attempt to exclude our liability.</li>
                                <li>We do not give any warranty for any goods or services accessed through, or displayed on, our site.</li>
                            </ol>
                        </li>
                    </ol>


                    <br />
                    <h4 className={styles.sectionTitleOrange}>13. Force Majeure</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>We shall not be liable for our failure to provide you with all or any features of Our Services if such breach is caused by Natural Force Majeure Event such as, without limitation, an act of God, system downtime, lockouts, accidents, cyber-attacks, or any event beyond our reasonable control. If such Force Majeure Event continues for a period longer than fifteen (15) days, you may opt to refund the Subscription Fees and notify us of your decision to that effect.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>14. Modifications and Amendments</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>We have right to make amendments or modification with reference to following without prior notice to you:</p>
                    <ol className={styles.dottedList}>
                        <li>Terms and Conditions</li>
                        <li>Features of Our Services</li>
                        <li>The modified version of the Terms and Conditions will be applicable immediately upon publication thereof on the Website.</li>
                        <li>We will have no liability for amendments and modifications. You are strongly recommended to review the Terms and Conditions, Privacy Policy and Pricing Page frequently. Your failure to review the pages will not waive you from your obligations and liabilities arising from any changes.</li>
                    </ol>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>15. Severability</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>If any court or competent authority decides that any of the provisions of these terms and conditions are invalid, unlawful or unenforceable to any extent, the term will, to that extent only, be severed from the remaining terms, which will continue to be valid to the fullest extent permitted by law.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>16. Entire Agreement</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>These terms and conditions and any document expressly referred to in them constitute the whole agreement between us and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us relating to the subject matter of these terms and conditions. We each acknowledge that, in entering into these terms and conditions, neither of us relies on, or will have any remedies in respect of, any representation or warranty (whether made innocently or negligently) that is not set out in these terms and conditions or the documents referred to in them. Nothing in this clause limits or excludes any liability for fraud.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>17. Intellectual Property</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>All copyrights, patents, trademarks, designs, ideas, know-how, concepts, graphics, text, drawings, information, content, technology, tools, apps, technical information, or material used in Our Services (“Intellectual Property”) shall be our exclusive property. No part of the Intellectual Property can be copied, used or exploited in any manner without our written approval.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>18. Changes to These Terms</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>We may update these Terms and Conditions from time to time.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>19. Governing Law</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>These terms are governed by the laws of England and Wales. Any disputes will be resolved in the courts of England and Wales.</p>

                    <br />
                    <h4 className={styles.sectionTitleOrange}>19. Contact Us</h4>
                    <br />
                    <p className={styles.sectionSubtitle}>If you have any questions or concerns, please contact us at <a href="mailto:support@devicetrackerplus.com" className={`${styles.linkOrange}`}>support@devicetrackerplus.com</a>.</p>
                    <br />
                    <br />
                </div >
            </div >
        </>
    )
}

export default Terms;