import styles from '../styles/dashboard.module.css';
import { Helmet } from 'react-helmet';
import DashboardPanel from '../components/auth/DashboardPanel';

const Dashboard = () => {
    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/faqs" />
            </Helmet>
            <div className={styles.mainContainer}>
                <div className={styles.mainPageCard}>
                    <DashboardPanel />
                </div>
            </div>
        </>
    );
}

export default Dashboard;