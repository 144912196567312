import { Helmet } from 'react-helmet';
import LocationSharingHero from '../components/heroSections/LocationSharingHero';
import Features from '../components/sharedComponents/Features';
import { loactionSharingFeatureList } from '../data/loactionSharingFeatureList';
import HowItWorks from '../components/sharedComponents/HowItWorks';
import WhyDtp from '../components/sharedComponents/WhyDtp';
import { bottomLoactionSharingFeatures } from '../data/bottomLoactionSharingFeatures';
import Testimonials from '../components/sharedComponents/Testimonials';
import { loactionSharingTestimonials } from '../data/loactionSharingTestimonials';

const LocationSharingCampaign = () => {
    return (
        <>
            <Helmet>
                <title>Location Sharing App for iPhone & Android - Device Tracker Plus</title>
                <meta name="description" content="Share your live location with friends and family with our accurate location sharing app. Downloadnow, stay connected, and keep peace of mind." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/locationSharing" />
            </Helmet>
            <LocationSharingHero />
            <Features sectionTitle="Share Your Live Location For Ease and Security" features={loactionSharingFeatureList} />
            <HowItWorks stepOne="Sign up online" stepTwo="Register device" stepThree="Start Tracking"/>
            <Testimonials testimonials={loactionSharingTestimonials} />
            <WhyDtp  reasons={bottomLoactionSharingFeatures}/>

        </>
    );
}

export default LocationSharingCampaign;