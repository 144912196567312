import styles from '../../styles/howItWorks.module.css';
import subscribeIcon from '../../assets/images/step_one.webp';
import chevronRight from '../../assets/images/chevronRight.webp';
import installApp from '../../assets/images/step_two.webp';
import startTracking from '../../assets/images/step_three.webp';


const HowItWorks = () => {
  return (
    <section className='maxScreen'>
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}><span className="hightLightText">Get started</span> with Device Tracker Plus In Minutes</h2>
      </div>
      <div className={styles.stepContainer}>
        <div className={styles.worksStep}>
          <div className={styles.stepInnerContainer}>
            <div className={styles.stepNumberContainer}><p className={styles.stepNumber}>1</p></div>
            <div className={styles.contentContainer}>
              <img src={subscribeIcon} alt="subscribe_icon" />
              <p className={styles.stepParagraph}>Subscribe online</p>
            </div>
          </div>
          <img src={chevronRight} alt="greyArrow" className="mobileDisabled"/>
        </div>
        <div className={styles.worksStep}>
          <div className={styles.stepInnerContainer}>
            <div className={styles.stepNumberContainer}><p className={styles.stepNumber}>2</p></div>
            <div className={styles.contentContainer}>
              <img src={installApp} alt="download_image" />
              <p className={styles.stepParagraph}>Install the app</p>
            </div>
          </div>
          <img src={chevronRight} alt="greyArrow" className="mobileDisabled" />
        </div>
        <div className={styles.worksStep}>
          <div className={styles.stepInnerContainer}>
            <div className={styles.stepNumberContainer}><p className={styles.stepNumber}>3</p></div>
            <div className={styles.contentContainer}>
              <img src={startTracking} alt="starttracking_image" />
              <p className={styles.stepParagraph}>Start tracking</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;