import React, {useEffect, useState} from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function DashboardPanel() {
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();
    const [statusData, setStatusData] = useState();
    const [dashboardData, setDashboardData] = useState();

    useEffect(() => {
        //userId

        async function checkPaymentStatus() {
            const userId = localStorage.getItem('userId');
            if (userId) {
                const response = await fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + "payment/status?userId=" + userId);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                let data = await response.json();
                setStatusData(data);
                console.log('pay status',data);
            }
        }
        async function getDashboard() {
            const userId = localStorage.getItem('userId');
            if (userId) {
                const response = await fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + "payment/dashboard?userId=" + userId);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                let data = await response.json();
                setDashboardData(data);
                console.log('setDashboardData',data);
            }
        }
        checkPaymentStatus();
        getDashboard();
    }, []);
    


    async function handleLogout() {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error('Failed to log out:', error);
        }
    }

    async function handleCancelAccount() {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error('Failed to log out:', error);
        }
    }
    return (
        <div>
            <h2>Dashboard</h2>

            <div >
                <h3>Account information</h3>
                <p>Email: {currentUser.email}</p>
                <p>Subscription: {statusData && statusData.isPaid ? 'Device Tracker Plus' : 'Unsubscribed'}</p>
            </div>

            <div style={{ marginTop: '2rem' }}>
                <h3>Billing information - last 12 months</h3>
                
                <p>January 2024 (Download icon)</p>
                <p>February 2024 (Download icon)</p>
                <p>March 2024 (Download icon)</p>
            </div>

            <div style={{ marginTop: '2rem' }}>
                <a href={'/help'} >Contact support</a>
                
                &nbsp;
                <button onClick={handleCancelAccount} className="btn btn-logout">
                    Cancel account
                </button>
                &nbsp;
                <button onClick={handleLogout} className="btn btn-logout">
                    Log Out
                </button>
            </div>

            
        </div>
    );
}