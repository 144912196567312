import React, { useState } from 'react';
import styles from '../../styles/nav.module.css';
import useMediaQuery from '../../hooks/useMediaQuery';
import logo from '../../assets/images/DtpLogoSmall.webp';
import { Bars3Icon, XMarkIcon, ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/solid';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';
import { useNavigate } from "react-router-dom";
import CheckoutPopup from '../sharedComponents/CheckoutPopup';

const NavBar = () => {

  const isAboveMediumScreens = useMediaQuery("(min-width: 1000px)");
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const showCheckoutPopup = () => {
    if (showPopup) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      setShowPopup(!showPopup);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  const closePopup = () => {
    setShowPopup(!showPopup);
    navigate(0);
  }


  return (
    <nav>
      <div className={styles.navMainContainer}>
        {!isAboveMediumScreens && <a href="/"><img src={logo} alt="logo" className={styles.logo} /></a>}
        {isAboveMediumScreens ?
          <div className={styles.desktopNavContainer}>
            <a href="/"><img src={logo} alt="logo" className={styles.logo} /></a>
            <div className={styles.featureMenuContainer} onClick={() => setIsOpen((prev) => !prev)}>
              <p className={styles.paragraph}>Device Tracker Plus is for...
                {
                  !isOpen ?
                    (<ChevronDoubleDownIcon className={styles.featureIcons} />) :
                    (<ChevronDoubleUpIcon className={styles.featureIcons} />)
                }
              </p>
              {isOpen && (
                <div className={styles.dropDownContainer}>
                  <div className={styles.dropDownItem}>
                    <a href={'/familyTracking'} className={styles.paragraph}>Family Tracking</a>
                  </div>
                  <div className={styles.dropDownItem}>
                    <a href={'/locationSharing'} className={styles.paragraph}>Loaction Sharing</a>
                  </div>
                  <div className={styles.dropDownItem}>
                    <a href={'/emergencyAlert'} className={styles.paragraph}>Emergency Alert</a>
                  </div>
                  <div className={styles.dropDownItem}>
                    <a href={'/track'} className={styles.paragraph}>Mobile Number Tracker</a>
                  </div>
                </div>
              )}
            </div>
            <ul className={styles.desktopNavList}>
              {/* <li>
                <a className={styles.link} href={'/whoWeAre'}>Who we are</a>
              </li> */}
              <li>
                <a className={styles.link} href={'/faqs'}>FAQ</a>
              </li>
              <li>
                <a className={styles.link} href={'/help'}>Help</a>
              </li>
              {/* <li>
                <a className={styles.link} href={'/Login'}>Login</a>
              </li> */}
              <li>
                <StartTrackingButton title="Start Tracking Now" style={styles.startTrackingButton} onButtonClick={showCheckoutPopup} />
              </li>
            </ul>
          </div>
          :
          <div className={styles.navMobileButton} id="navMobile" title="navMobile"
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            {
              !isMenuToggled ? <Bars3Icon className={styles.mobileMenuIcon} /> : <XMarkIcon className={styles.mobileMenuIcon} />
            }
          </div>
        }

      </div>
      {/* Mobile Menu */}
      {!isAboveMediumScreens && isMenuToggled && (
        <div className={styles.mobileNav}>
          <ul className={styles.mobileNavMenuList}>
            <li >
              <a className={styles.mobileNavItem} href={'/'}>Home</a>
            </li>
            <li
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <div className={styles.mobileFeatureMenuContainer}>
                <p className={styles.mobileNavItem}>DTP App is for... {
                  !isOpen ?
                    (<ChevronDoubleDownIcon className={styles.featureIcons} />) :
                    (<ChevronDoubleUpIcon className={styles.featureIcons} />)
                }</p>
                {isOpen && (
                  <div className={styles.mobileDropDownContainer}>
                    <div className={styles.mobileDropDownItem}>
                      <a className={styles.mobileNavItem} href={'/familyTracking'}>Family Tracking</a>
                    </div>
                    <div className={styles.mobileDropDownItem}>
                      <a className={styles.mobileNavItem} href={'/locationSharing'}>Loaction Sharing</a>
                    </div>
                    <div className={styles.mobileDropDownItem}>
                      <a className={styles.mobileNavItem} href={'/emergencyAlert'}>Emergency Alert</a>
                    </div>
                    <div className={styles.mobileDropDownItem}>
                      <a className={styles.mobileNavItem} href={'/track'}>Mobile Number Tracker</a>
                    </div>
                  </div>
                )}
              </div>
            </li>
            <li >
              <a href={'/whoWeAre'} className={styles.mobileNavItem}>Who we are</a>
            </li>
            <li >
              <a href={'/faqs'} className={styles.mobileNavItem} >FAQ</a>
            </li>
            <li >
              <a href={'/help'} className={styles.mobileNavItem}>Help</a>
            </li>

          </ul>
        </div>
      )}
      {showPopup &&
        <CheckoutPopup closePopup={closePopup} country={''} carrier={''} confrmed={''} trial={0} userData={false} />}
    </nav>
  );
}

export default NavBar;