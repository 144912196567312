import React, { useEffect, useState } from 'react';
import styles from '../../styles/nav.module.css';
import logo from '../../assets/images/logo.webp';
import facebook from '../../assets/images/facebook.webp';
import instagram from '../../assets/images/instagram.webp';
import tikTok from '../../assets/images/tikTokIcon.webp';


const FooterNav = () => {

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    const currentPage = window.location;
    setCurrentUrl(currentPage.pathname);
    console.log('Current Page: ', currentPage.pathname);
  }, [])

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }



  return (
    <nav>
      <div className={styles.footerNavContainer}>
        <div>
          <div onClick={scrollToTop}>
            <img src={logo} width={130} height={50} alt="logo" className={styles.footerLogo} />
          </div>
          <div className={styles.socialMedia}>
            <a href="https://www.facebook.com/people/Device-Tracker-Plus/100065657734292/" target='blank'>
              <img src={facebook} alt="facebook_logo" className={styles.socialIcon} />
            </a>
            <a href="https://www.instagram.com/devicetrackerplus/" target='blank'>
              <img src={instagram} alt="instagram_logo" className={styles.socialIcon} /></a>
            <a href="https://www.tiktok.com/@devicetrackerplus?lang=en" target='blank'>
              <img src={tikTok} alt="tiktok_logo" className={styles.socialIcon} />
            </a>
          </div>
        </div>
        <div className={styles.midFooterContainer}>
          <ul className={styles.footerNavList}>
            <li>
              <a className={styles.link} href="/terms">Terms</a>
            </li>
            <li >
              <a className={styles.link} href={'/policy'}>Privacy Policy</a>
            </li>
            <li >
              <a className={styles.link} href={'/whoWeAre'}>About</a>
            </li>
            <li >
              <a className={styles.link} href={'/help'}>Contact</a>
            </li>
            {currentUrl !== '/faqs' && <li >
              <a className={styles.link} href={'/faqs'}>FAQ's</a>
            </li>}
            <li >
              <a className={styles.link} href="https://blog.devicetrackerplus.com/">Blog</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default FooterNav;