import React, { useState } from 'react';
import styles from '../../styles/newHome.module.css';
import useMediaQuery from '../../hooks/useMediaQuery';
import heroImage from '../../assets/images/DTPHerobigGraphic.webp';
import PhoneVerification from '../sharedComponents/PhoneVerification';



const TrackHero = () => {

    const [showSearchInput, setShearchInput] = useState(true);

    const isAboveMediumScreens = useMediaQuery("(min-width: 850px)");

    const togglePhoneVerificationPopup = (e) => {
        e.preventDefault();
        setShearchInput(!showSearchInput);
    }

    return (
        <section>
            <div className={styles.heroBackground}>
                <div className={styles.altHomeHeroInnerContainer}>
                    {!isAboveMediumScreens && <div className={styles.imageContainer}>
                        <div className={styles.imageBgc}> </div>
                        <img src={heroImage} alt="heroGraphic" className={styles.heroImg} />
                    </div>}
                    <div className={styles.altHeroContainer}>
                        <div className={styles.headingContainer}>
                            <h1 className={styles.heading}><span className="hightLightText">Get peace of mind</span>
                                <br></br> with the #1 location tracker.</h1>
                            <p className={styles.paragraph}>
                                Over <span className="hightLightText">5 million</span> users worldwide.
                            </p>
                            {showSearchInput && <PhoneVerification showPopUp={togglePhoneVerificationPopup} />}
                        </div>
                    </div>
                    {isAboveMediumScreens && <div className={styles.imageContainer}>
                        <div className={styles.imageBgc}> </div>
                        <img src={heroImage} alt="heroGraphic" className={styles.heroImg} />
                    </div>}
                </div>
            </div>
        </section>
    )
}

export default TrackHero;