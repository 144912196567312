import React from 'react';
import styles from '../../styles/testimonials.module.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import chevronDown from '../../assets/images/chevronDown.webp';
import chevronUp from '../../assets/images/chevronUp.webp';

const VerticalCarousel = ({ testimonials }) => {
    return (
        <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={6}
            visibleSlides={1}
            orientation='vertical'
        >
            <ButtonBack className={styles.buttonContainer}><img src={chevronUp} alt="" /></ButtonBack>
            <Slider>
                {testimonials.map((testimonial) => (
                    <Slide index={0} key={testimonial.id}>
                        <div className={styles.testimonialContainer}>
                            <p className="paragraph">{testimonial.title}</p>
                            <br></br>
                            <div>
                                ⭐⭐⭐⭐⭐
                            </div>
                            <br></br>
                            <p className="smallParagraph">&quot;{testimonial.content}&quot;</p>
                            <br></br>
                            <p className="smallParagraph hightLightText">{testimonial.author}</p>
                        </div>
                    </Slide>
                ))}
            </Slider>
            <ButtonNext className={styles.bottomButton}><img src={chevronDown} alt="" /></ButtonNext>
        </CarouselProvider>
    )
}

export default VerticalCarousel