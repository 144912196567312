import React from 'react';
import styles from '../styles/whoWeAre.module.css';
import leftArrow from '../assets/images/leftArrow.svg';
import rightArrow from '../assets/images/rightArrow.svg';
import { Helmet } from 'react-helmet';
import SignIn from '../components/auth/SignIn';

const Login = () => {

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/whoWeAre" />
            </Helmet>
            <div className={styles.pageContainer}>
                <div className={styles.mainContainer}>
                    <div className={styles.topSectionContainer}>

                        <SignIn />

                    </div>

                    <div className={styles.bottomContainer}>
                        
                        <div className={styles.bottomIconsContainer}>
                            <img src={leftArrow} width={150} height={50} alt="leftArrow" className={styles.leftArrow} loading="lazy" />
                            <img src={rightArrow} width={150} height={50} alt="rightArrow" className={styles.rightArrow} loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;