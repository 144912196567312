import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/heroSections/Hero';
import Features from '../components/sharedComponents/Features';
import HowItWorks from '../components/sharedComponents/HowItWorks';
import Testimonials from '../components/sharedComponents/Testimonials';
import WhyDtp from '../components/sharedComponents/WhyDtp';
import { useNavigate } from "react-router-dom";
import { homeFeature_new } from '../data/homeFeature_new';
import { testimonials } from "../data/testimonials";
import { whyDtpFeatures } from '../data/whyDtpFeatures';
import CheckoutPopup from '../components/sharedComponents/CheckoutPopup';
import { DtpUtils } from '../utils/DtpUtils';

const Home = () => {

    const [togglePopup, setToggleOpup] = useState(false);
    const navigate = useNavigate();

    const openPopup = (togglePopup) => {
        const isOpne = DtpUtils.showCheckoutPopup(togglePopup);
        setToggleOpup(isOpne);
    }

    const closePopup = () => {
        setToggleOpup(!togglePopup);
        navigate(0);
    }

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/HomeNew" />
            </Helmet>
            <Hero showCheckoutPopup={openPopup} />
            <Features features={homeFeature_new} showCheckoutPopup={openPopup} />
            <HowItWorks />
            <Testimonials testimonials={testimonials} showCheckoutPopup={openPopup} />
            <WhyDtp reasons={whyDtpFeatures} showCheckoutPopup={openPopup} />
            {togglePopup &&
                <CheckoutPopup closePopup={closePopup} country={''} carrier={''} confrmed={''} trial={0} userData={false} />}
        </>
    )
}

export default Home;