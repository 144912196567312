
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
    onAuthStateChanged,
    signOut,
} from 'firebase/auth';
import { auth } from '../utils/Firebase';
import { useNavigate } from "react-router";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Action URL is where user will be redirected after clicking the magic link
    const actionCodeSettings = {
        url: window.location.origin + '/dashboard',
        handleCodeInApp: true
    };

    // Send magic link email
    async function sendMagicLink(email) {
        // Save the email locally to use it later for sign-in
        localStorage.setItem('emailForSignIn', email);

        
        //const actionLink =  await getAuth()
        //generateEmailVerificationLink(userEmail, actionCodeSettings)
        return sendSignInLinkToEmail(auth, email, actionCodeSettings);
    }

    // Complete sign in with magic link
    async function confirmSignIn() {
        // Get the email from localStorage
        const email = localStorage.getItem('emailForSignIn');

        if (!email) {
            throw new Error("Email not found. Please try signing in again.");
        }

        // Check if the URL contains the sign-in link
        if (isSignInWithEmailLink(auth, window.location.href)) {
            try {
                // Complete the sign-in process
                await signInWithEmailLink(auth, email, window.location.href);

                // Clear email from storage
                localStorage.removeItem('emailForSignIn');

                
                // Remove the email sign-in URL from the URL bar
                //window.history.replaceState(null, '', window.location.origin+ '/dashboard');
                navigate('/dashboard');
                return true;
            } catch (error) {
                console.error("Error signing in:", error);
                throw error;
            }
        }

        return false;
    }

    // Logout function
    function logout() {
        return signOut(auth);
    }

    // Listen for auth state changes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        // Try confirming sign-in when component mounts
        if (isSignInWithEmailLink(auth, window.location.href)) {
            confirmSignIn().catch(console.error);
        }

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        sendMagicLink,
        confirmSignIn,
        logout,
        isSignInWithEmailLink: () => isSignInWithEmailLink(auth, window.location.href)
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}