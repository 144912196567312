import styles from '../../styles/policy.module.css';
import ContactDetails from './ContactDetails';

const DataCollecting = () => {
    return (
        <div>
            <h4 className={styles.sectionTitleOrange} id="dataCollect">What Data We Collect About You</h4>
            <p className={styles.sectionSubtitle}>This privacy notice provides you with details of how we collect and process your personal data through your use of our services, including our website: <a href="http://www.devicetrackerplus.com" className={`${styles.linkOrange}`}>www.devicetrackerplus.com</a>,
                our app, and any other way you may interact with Device Tracker Plus. This includes any information, you may provide through our site when you purchase a product or service, sign up to our newsletter, or take part in a prize draw or competition.</p>
            <p className={styles.sectionSubtitle}>By providing us with your data, you warrant to us that you are over 18 years of age.
                Boston Digital Limited is the data controller and we are responsible for your personal data (referred to as “we”, “us” or “our” in this privacy notice). Our ICO Registration Number is ZA221596.</p>
            <p className={styles.sectionSubtitle}>If you have any questions about this privacy notice, please contact the Data Protection Officer using the details set out below.</p>
            <ContactDetails />
            <h4 className={styles.sectionTitleOrange} id="howCollectData">How We Collect Your Personal Data</h4>
            <p className={styles.sectionSubtitle}>Personal data means any information capable of identifying an individual. It does not include anonymised data. The information we collect depends on how you use our services.</p>
            <ol className={styles.dataCollectionList}>
                <li style={{ color: '#F15223', marginLeft: '40px' }} id="directCollect">Information You Give Us Directly</li>
                <p className={styles.sectionSubtitle}>We may process certain types of personal data about you from your own inputs. When you create or change a Device Tracker Plus account, contact us, or otherwise interact with our products and services we collect a variety of information, including:</p>
                <ol className={styles.dottedList}>
                    <li><span>Identity Data</span>: When you first sign up to Device Tracker Plus you will need to provide your full name, username and date of birth.</li>
                    <li><span>Contact Data</span>: This may include your billing address, email address and telephone numbers.</li>
                    <li><span>Transaction Data</span>: Any payment information you provide will be processed by the relevant billing or payment processor. We may also collect details such as the date and time when you activated your account, the duration of your subscription, and any data related to subscription cancellation requests.</li>
                    <li><span>Profile Data</span>: This may include your username and password, purchases or orders. You may also choose to attach a photo to your profile within the Device Tracker Plus app. Additionally, we collect the usernames and photos (if provided) of other members who join your group.
                        <br></br>
                        <br></br>
                        We also may gather information when you customise features within our services, such as saving locations on your in-app map in Device Tracker Plus. We also collect information when you use messaging features in our services and any photos or videos you send via the in-app messaging. With your permission, we may also access your photo or camera roll on your mobile device.
                    </li>
                    <li><span>Interaction Data</span>: We collect information when you provide feedback to Device Tracker Plus or interact with us, such as commenting on a blog post or tagging Device Tracker Plus in a social media post. This also includes information you provide in response to surveys about our services, any details about your friends that you include in our referral services, and information you submit when seeking assistance, such as your name, email address, telephone number, mailing address, and records (including call recordings) that relate to the issues you have experienced.</li>
                    <li><span>Marketing and Communications Data</span>: This may include your preferences in receiving marketing communications from us and our third parties and your communication preferences.</li>
                    <li><span>Technical Data</span>: This may include your login data, internet protocol addresses, browser type and version, browser plug-in types and versions, time zone setting and location, operating system and platform and other technology on the devices you use to access this site.</li>
                </ol>
                <li style={{ color: '#F15223', marginLeft: '40px' }} id="autoCollect">Information we automatically Collect</li>
                <p className={styles.sectionSubtitle}>We and our service providers may collect other information when you use our services, such as:</p>
                <ol className={styles.dataSubList}>
                    <li><span>Technical Data</span>: This may include information about your device and its hardware and software, such as mobile device identifiers, operating system, IP address, browser information (including type and language preference), time zone settings, location, operating system and platform, device make and model, Wi-Fi connection, battery status, advertising identifiers, and other technologies found on the devices you use to access this site. </li>
                    <li><span>Usage Data</span>: When you use our products or services, such as our apps, websites, or emails, we and our partners may employ various technologies, including cookies, web beacons, pixels, clear GIFs, log files, embedded scripts, SDKs, location-identifying technologies, or other tracking tools (collectively referred to as "Tracking Technologies"). These tools help us collect information about your activity on our services, such as the links you click, features you use, and actions you take. This includes whether you open our emails, click on links in those emails, view or interact with ads, and details about your activity on specific web pages, such as mouse movements, keystrokes, and app performance data. We may combine this information with other personal data we collect about you.</li>
                    <li><span>Location and Movement Data</span>: With your consent, we collect precise geolocation data (including GPS coordinates and latitude/longitude information) through your mobile device to provide you with our services. Through this and other information collected from technology embedded in your mobile device, such as the gyroscope, accelerometer, compass, and Bluetooth, we may derive information about how and where you are moving such as your speed, altitude, and heading.</li>
                    <li><span>Third Party Data</span>: We may receive personal data about you from various third parties and public sources, including:
                        <ol className={styles.subList}>
                            <li>Analytics providers, such as Google, based outside the EU.</li>
                            <li>Advertising networks, such as Facebook, based outside the EU. </li>
                        </ol>
                    </li>
                </ol>
            </ol>
            <br />
            <p className={styles.sectionSubtitle}>We may also process Aggregated Data from your personal data, but this data does not reveal your identity and as such is not personal data. An example of this is where we review your Usage Data to work out the percentage of website users using a specific feature of our site. If we link the Aggregated Data with your personal data so that you can be identified from it, then it is treated as personal data. </p>
            <h4 className={styles.sectionTitleOrange} id="sensitiveData">Sensitive Data</h4>
            <p className={styles.sectionSubtitle}>We do not collect any Sensitive Data about you. Sensitive data refers to data that includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data. We do not collect any information about criminal convictions and offences.
                <br />
                <br />
                Where we are required to collect personal data by law, or under the terms of the contract between us and you do not provide us with that data when requested, we may not be able to perform the contract (for example, to deliver goods or services to you). If you don’t provide us with the requested data, we may have to cancel a product or service you have ordered but if we do, we will notify you at the time.
            </p>
        </div>
    )
}

export default DataCollecting;