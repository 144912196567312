import styles from '../styles/policy.module.css';
import List from '../components/policy/List';
import Introduction from '../components/policy/Introduction';
import PolisyTerms from '../components/policy/PolicyTerms';
import DataCollecting from '../components/policy/DataCollecting';
import ProcessingData from '../components/policy/ProcessingData';
import Regulations from '../components/policy/Regulations';
import Cookie from '../components/policy/Cookie';
import { Helmet } from 'react-helmet';


const Policy = () => {

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
                <link rel="canonical" href="https://www.devicetrackerplus.com/policy" />
            </Helmet>
            <div className={styles.mainContainer}>
                <div className={styles.fullInnerCard}>
                    <h1 className={styles.pageTitle}>Device Tracker Plus Privacy Notice & Cookie Policy</h1>
                    <h4 className={styles.sectionSubtitle}>Boston Digital Limited has a responsibility to document how we will protect your personal data.</h4>
                    <h4 className={styles.sectionSubtitle}>This is a legal requirement of the Data Protection Act (2018), Part 2, within the UK GDPR ‘Right to be Informed’.</h4>
                    <h4 className={styles.sectionSubtitle}>This Privacy Notice will outline our responsibilities to you.</h4>
                    <h4 className={styles.sectionSubtitle}>Effective as of 6th February 2025</h4>
                    <List />
                    <Introduction />
                    <PolisyTerms />
                    <DataCollecting />
                    <ProcessingData />
                    <br />
                    <Regulations />
                    <Cookie />
                    <h4 className={styles.sectionTitleOrange} id="version">Version</h4>
                    <p className={styles.sectionSubtitle}>This policy was updated on 6th February 2025</p>
                </div>
            </div>
        </>
    )
}

export default Policy;